import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { CompanyDetailsDialog } from '../dialogs';
import { useBooleanState } from '../../hooks';

const CompanyDetailsButton = ({ companyId }) => {
  const [open, toggleBoolean, setTrue] = useBooleanState(false);
  return (
    <>
      <IconButton
        variant="contained"
        size="small"
        color="primary"
        onClick={setTrue}
      >
        <OpenInNew fontSize="5" color="text.primary" />
      </IconButton>
      <CompanyDetailsDialog companyId={companyId} open={open} setOpen={toggleBoolean} />
    </>
  );
};

CompanyDetailsButton.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyDetailsButton;
