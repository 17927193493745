import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import { DownloadExcelIcon } from '../icons';

const DownloadExcelIconButton = ({
  downloadFunction,
  loading,
  disabled,
  title,
  ...props
}) => (
  <Tooltip title={title}>
    <span {...props}>
      <LoadingButton
        onClick={downloadFunction}
        loading={loading}
        size="small"
        id="export-excel"
        disabled={disabled}
        sx={{ height: '28px', minWidth: 0, borderRadius: 10000, color: 'success.A700' }}
      >
        <DownloadExcelIcon />
      </LoadingButton>
    </span>
  </Tooltip>
);

DownloadExcelIconButton.propTypes = {
  downloadFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

DownloadExcelIconButton.defaultProps = {
  disabled: false,
  title: '',
};

export default DownloadExcelIconButton;
