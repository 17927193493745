import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const IconButtonFilled = ({ id, icon, handleOnClick, disabled, ...restStyles }) => (
  <IconButton
    id={id}
    onClick={handleOnClick}
    disabled={disabled}
    sx={{
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
      '&:hover': {
        backgroundColor: 'primary.A800',
      },
      '&:disabled': {
        backgroundColor: 'action.disabledBackground',
        color: 'gray.A600',
        boxShadow: 0,
      },
      ...restStyles,
    }}
  >
    {icon}
  </IconButton>
);

IconButtonFilled.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  handleOnClick: PropTypes.func,
  disabled: PropTypes.bool,
};

IconButtonFilled.defaultProps = {
  handleOnClick: () => {},
  disabled: false,
};

export default IconButtonFilled;
