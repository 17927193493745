import React from 'react';
import PropTypes from 'prop-types';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useBooleanState from '../../hooks/useBooleanState';
import UploadDocumentsManager from '../inputs/UploadDocumentsManager';

const UploadDocumentButton = ({ invoiceId, showTitle, ...props }) => {
  const [open, toggleOpen] = useBooleanState(false);

  return (
    <>
      <Tooltip title="Adjuntar archivos">
        <IconButton
          onClick={toggleOpen}
          size="large"
          id="openUploadDocuments"
          disableRipple
        >
          <UploadFileRoundedIcon />
          {showTitle && <Typography {...props}>Documentos</Typography>}
        </IconButton>
      </Tooltip>
      {open && (
        <UploadDocumentsManager
          open={open}
          handleClose={toggleOpen}
          invoiceId={invoiceId}
        />
      )}
    </>
  );
};

UploadDocumentButton.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

UploadDocumentButton.defaultProps = {
  showTitle: false,
};

export default UploadDocumentButton;
